import locale2 from 'locale2';
import includes from 'ramda/src/includes';
import isNil from 'ramda/src/isNil';

const BROWSER_LOCALE = locale2;
const NON_COMMA_LOCALE_SUBTAGS = ['he'];

const getLocaleSubtag = fullLocale => {
    if (!isNil(fullLocale)) {
        return fullLocale.split('-')[0];
    } else {
        return null;
    }
};

const BROWSER_LOCALE_SUBTAG = getLocaleSubtag(BROWSER_LOCALE);
export const IS_NON_COMMA_LOCALE = includes(BROWSER_LOCALE_SUBTAG, NON_COMMA_LOCALE_SUBTAGS);
export default BROWSER_LOCALE;
