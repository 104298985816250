import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { arrayOf, bool, func, number } from 'prop-types';
import classnames from 'classnames';
import { withVisibilityLogic, useTrackMessageVisibilityEffect } from 'mangools-react-components';
import { useDispatch } from 'react-redux';

import MessageHolder from 'components/messages/MessageHolder';
import AddToListMessageNew from 'components/messages/addToListMessage/AddToListMessageNew';
import AddToListMessageExisting from 'components/messages/addToListMessage/AddToListMessageExisting';

import ListType from 'types/ListType';

import { analyticsEvents } from 'constants/analytics';

import { gtmTrack } from 'actions/analyticsActions';

const VIEW_EXISTING_LISTS = 1;
const VIEW_NEW_LIST = 2;

function AddToListMessage(props) {
    const dispatch = useDispatch();

    const hasSomeLists = () => props.sortedListData.length > 0;
    const getCurrentView = () => (hasSomeLists() ? VIEW_EXISTING_LISTS : VIEW_NEW_LIST);

    const [currentView, setCurrentView] = useState(getCurrentView());

    useEffect(() => {
        if (props.sortedListData.length === 0) {
            setCurrentView(getCurrentView());
        }
    }, [props.sortedListData]);

    const onGtmTrack = useCallback(
        payload => {
            dispatch(gtmTrack(payload));
        },
        [dispatch],
    );

    useTrackMessageVisibilityEffect(analyticsEvents.ADD_TO_LIST, onGtmTrack);

    const handleShowAddToExisting = () => {
        setCurrentView(VIEW_EXISTING_LISTS);
    };

    const handleShowAddToNew = () => {
        setCurrentView(VIEW_NEW_LIST);
    };

    const handleExistingListSelected = listId => props.onListChosen(false, listId);

    const handleNewListCreated = listName => props.onListChosen(true, listName);

    const renderBody = () => {
        switch (currentView) {
            case VIEW_EXISTING_LISTS: {
                return (
                    <AddToListMessageExisting
                        firstList={props.defaultList}
                        addingKeywordCount={props.addingKeywordCount}
                        listData={props.sortedListData}
                        onExistingListSelected={handleExistingListSelected}
                        fetchingLists={props.fetchingLists}
                    />
                );
            }
            case VIEW_NEW_LIST: {
                return (
                    <AddToListMessageNew
                        addingKeywordCount={props.addingKeywordCount}
                        onCreateNewList={handleNewListCreated}
                    />
                );
            }
            default: {
                return null;
            }
        }
    };

    return (
        <MessageHolder onClose={props.onClose} preventCloseButtonFocus>
            <div className="mg-modal-header">
                <FontAwesomeIcon icon="star" className="color-orange" />
                <h3 className="font-30 uk-text-bold color-black">Add keywords to a list</h3>
            </div>
            <ul className="uk-tab uk-text-center mg-tab is-light is-small">
                <li
                    className={classnames('uk-width-1-2', {
                        'uk-active': currentView === VIEW_EXISTING_LISTS,
                    })}
                >
                    <button onClick={handleShowAddToExisting} type="button" disabled={!hasSomeLists()}>
                        Existing list
                    </button>
                </li>
                <li
                    className={classnames('uk-width-1-2', {
                        'uk-active': currentView === VIEW_NEW_LIST,
                    })}
                >
                    <button onClick={handleShowAddToNew} type="button">
                        New list
                    </button>
                </li>
            </ul>
            <div className="mg-modal-content bg-fff uk-padding-remove">
                <div className="kw-addtolist-container mg-padding-30 uk-flex uk-flex-column">{renderBody()}</div>
            </div>
        </MessageHolder>
    );
}

AddToListMessage.propTypes = {
    defaultList: ListType,
    addingKeywordCount: number.isRequired,
    onClose: func.isRequired,
    onListChosen: func.isRequired,
    sortedListData: arrayOf(ListType).isRequired,
    fetchingLists: bool.isRequired,
};

export default withVisibilityLogic(React.memo(AddToListMessage));
