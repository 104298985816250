import React, { Component } from 'react';
import { Link } from 'react-router';
import { func, number, string, bool } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { isNil, trim } from 'ramda';
import { Input } from 'mangools-react-components';

import swImage from 'images/serpwatcher-icon.svg';

import Defaults from 'mangools-commons/lib/constants/Defaults';
import NumberFormatterService from 'mangools-commons/lib/services/NumberFormatterService';

import DateFormatterService from 'services/DateFormatterService';
import { DEFAULT_LIST_KEYWORDS_MAX_LIMIT } from 'reducers/userReducer';

import Tooltip from 'components/other/Tooltip';

class ListPanelItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            editing: false,
            newName: this.props.name,
        };

        this.deleteList = this.deleteList.bind(this);
        this.exportList = this.exportList.bind(this);
        this.formatTimestamp = this.formatTimestamp.bind(this);
        this.renderLink = this.renderLink.bind(this);
        this.renderLinkBody = this.renderLinkBody.bind(this);
        this.handleEditClick = this.handleEditClick.bind(this);
        this.handleNewNameChange = this.handleNewNameChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleTrackInSerpwatcherClick = this.handleTrackInSerpwatcherClick.bind(this);

        this.nameInput = React.createRef();
    }

    deleteList() {
        this.props.onDeleteList({ listId: this.props.id, listName: this.props.name });
    }

    exportList() {
        this.props.onExportList(this.props.id);
    }

    handleEditClick(e) {
        e.preventDefault();

        if (this.state.editing === false) {
            this.setState({ editing: true }, () => this.nameInput.current.focus());
        } else if (this.state.editing === true) {
            this.nameInput.current.blur();
        }
    }

    handleBlur() {
        const newName = trim(this.state.newName);

        if (newName.length >= 1) {
            if (newName !== this.props.name) {
                this.props.onRenameList(this.props.id, this.state.newName);
            } else {
                this.setState({ newName });
            }
        } else {
            this.setState({ newName: this.props.name });
        }

        this.setState({
            editing: false,
        });
    }

    handleNewNameChange(e) {
        this.setState({
            newName: e.target.value,
        });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.nameInput.current.blur();
    }

    handleTrackInSerpwatcherClick() {
        this.props.onShowTrackInSerpwatcherMessage(this.props.id);
    }

    formatTimestamp(timestamp) {
        if (!isNil(timestamp)) {
            return DateFormatterService.formatBasic(timestamp);
        } else {
            return Defaults.NOT_AVAILABLE;
        }
    }

    /* eslint-disable jsx-a11y/anchor-is-valid */
    renderLink() {
        const wrapperClasses = classnames('mg-card', 'mg-padding-30', 'mg-no-underline', 'uk-display-block', {
            'is-focus': this.props.isPreselected,
            'is-hover': this.state.editing === false,
        });

        if (this.props.keywordCount > 0) {
            return this.state.editing ? (
                <div className={wrapperClasses}>{this.renderLinkBody()}</div>
            ) : (
                <Link onClick={this.props.onClose} to={this.props.showListLink} className={wrapperClasses}>
                    {this.renderLinkBody()}
                </Link>
            );
        } else {
            return (
                <div
                    className="mg-card mg-padding-30 mg-no-interactive"
                    style={{ opacity: this.props.keywordCount > 0 ? 1 : 0.5 }}
                >
                    {this.renderLinkBody()}
                </div>
            );
        }
    }
    /* eslint-enable jsx-a11y/anchor-is-valid */

    /* eslint-disable max-len */
    renderLinkBody() {
        const badgeClasses = classnames({
            'color-red': this.props.keywordCount >= DEFAULT_LIST_KEYWORDS_MAX_LIMIT,
            'color-black': this.props.keywordCount < DEFAULT_LIST_KEYWORDS_MAX_LIMIT,
        });

        return (
            <>
                <div className="kw-listname font-18 mg-truncate uk-display-inline-block uk-text-bold uk-width-5-10 uk-width-medium-5-10">
                    {this.state.editing ? (
                        <form
                            onSubmit={this.handleSubmit}
                            className="uk-block uk-width-1-1 uk-position-relative color-black"
                            style={{ marginTop: '-5px' }}
                        >
                            <FontAwesomeIcon
                                icon="pencil-alt"
                                aria-hidden="true"
                                className="font-12 mg-margin-r-5 color-grey"
                            />
                            <Input
                                className="mg-editable is-small mg-truncate uk-width-1-1 uk-text-bold"
                                minLength={2}
                                maxLength={100}
                                onBlur={this.handleBlur}
                                onChange={this.handleNewNameChange}
                                inputRef={this.nameInput}
                                required
                                spellCheck={false}
                                title={this.state.newName}
                                type="text"
                                value={this.state.newName}
                            />
                        </form>
                    ) : (
                        <span>{this.state.newName}</span>
                    )}
                </div>
                <div className="uk-flex font-12 color-grey mg-margin-t-5">
                    <div className="uk-width-1-3">
                        <span title="Number of keywords in list">
                            <strong className={badgeClasses}>
                                {NumberFormatterService.simpleCommaNoDecimals(this.props.keywordCount)}
                            </strong>
                            &nbsp;/ {NumberFormatterService.simpleCommaNoDecimals(DEFAULT_LIST_KEYWORDS_MAX_LIMIT)}
                        </span>
                    </div>
                    <div className="uk-width-1-3">
                        <span title={`Created: ${this.formatTimestamp(this.props.createdAt)}`}>
                            {this.formatTimestamp(this.props.updatedAt)}
                        </span>
                    </div>
                </div>
            </>
        );
    }

    render() {
        return (
            <div className="mg-margin-b-15 uk-position-relative ln-11">
                {this.renderLink()}
                <div className="uk-position-top-right mg-padding-30 uk-position-z-index uk-height-1-1 uk-flex uk-flex-middle">
                    <button
                        aria-label="Track in SERPWatcher"
                        className="mg-btn is-white is-xsmall mg-margin-r-10"
                        type="button"
                        onClick={this.handleTrackInSerpwatcherClick}
                        disabled={this.props.keywordCount === 0}
                    >
                        <img alt="SERPWatcher" src={swImage} style={{ marginTop: '-2px' }} width="10" />
                        <span className="uk-hidden-small mg-margin-l-5">Track keywords</span>
                    </button>
                    <Tooltip overlayContent="Rename list">
                        <button
                            aria-label="Rename the list"
                            className="mg-icon-btn"
                            onMouseDown={this.handleEditClick}
                            tabIndex="-1"
                            type="button"
                        >
                            <FontAwesomeIcon icon="pencil-alt" />
                        </button>
                    </Tooltip>
                    <Tooltip overlayContent="Export list">
                        <button
                            aria-label="Export to CSV"
                            className="mg-icon-btn"
                            onClick={this.exportList}
                            tabIndex="-1"
                            type="button"
                            disabled={this.props.keywordCount === 0}
                        >
                            <FontAwesomeIcon icon="download" />
                        </button>
                    </Tooltip>
                    <Tooltip overlayContent="Delete list">
                        <button
                            aria-label="Delete list"
                            className="mg-icon-btn"
                            onClick={this.deleteList}
                            tabIndex="-1"
                            type="button"
                        >
                            <FontAwesomeIcon icon={['far', 'trash-alt']} />
                        </button>
                    </Tooltip>
                </div>
            </div>
        );
    }
    /* eslint-enable max-len */
}

ListPanelItem.propTypes = {
    createdAt: number.isRequired,
    id: string.isRequired,
    keywordCount: number.isRequired,
    name: string.isRequired,
    onClose: func.isRequired,
    onDeleteList: func.isRequired,
    onExportList: func.isRequired,
    showListLink: string.isRequired,
    updatedAt: number.isRequired,
    isPreselected: bool.isRequired,
    onRenameList: func.isRequired,
    onShowTrackInSerpwatcherMessage: func.isRequired,
};

export default ListPanelItem;
