import React from 'react';
import { func, number, string, bool } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Favicon } from 'mangools-react-components';
import UrlService from 'mangools-commons/lib/services/UrlService';
import classnames from 'classnames';

import KeywordDifficultyRank from 'components/other/KeywordDifficultyRank';
import HistoryItemTypes from 'constants/HistoryItemTypes';

import LanguageType from 'types/LanguageType';
import LocationType from 'types/LocationType';

const HistoryPanelItem = React.memo(props => {
    const handleClick = () => {
        props.onClick(props.type, {
            query: props.query,
            languageId: props.language.id,
            locationId: props.location.id,
        });
    };

    const wrapperClasses = classnames('mg-results-tr', 'uk-flex', 'cursor-pointer', {
        'is-preselected': props.isPreselected,
    });

    /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
    if (props.type === HistoryItemTypes.COMPETITOR) {
        return (
            <div className={wrapperClasses} role="listitem" onClick={handleClick}>
                <div className="mg-results-td is-80 uk-flex uk-flex-middle">
                    <Favicon className="mg-margin-l-10" domain={UrlService.getRoot(props.query)} size={16} />
                </div>
                <div className="mg-results-td uk-flex-item-auto mg-truncate">
                    <span className="font-16 mg-truncate">{props.query}</span>
                    <div className="mg-truncate">
                        <span className="mg-truncate font-12">
                            <FontAwesomeIcon icon="map-marker-alt" className="color-grey mg-margin-r-5" />
                            {props.location.label}
                        </span>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className={wrapperClasses} role="listitem" onClick={handleClick}>
                <div className="mg-results-td is-80 uk-flex uk-flex-middle">
                    <KeywordDifficultyRank rank={props.rank} tooltipPlacement="right" updatedAt={props.rankUpdatedAt} />
                </div>
                <div className="mg-results-td uk-width-8-10 mg-truncate">
                    <span className="font-16 mg-truncate">{props.query}</span>
                    <div className="mg-truncate">
                        <span className="mg-truncate font-12">
                            <FontAwesomeIcon icon="map-marker-alt" className="color-grey mg-margin-r-5" />
                            {props.location.label}
                        </span>
                        <span className="mg-truncate font-12 mg-margin-l-10">
                            <FontAwesomeIcon icon="language" className="color-grey mg-margin-r-5" />
                            {props.language.label}
                        </span>
                    </div>
                </div>
            </div>
        );
    }
    /* eslint-enable jsx-a11y/no-noninteractive-element-interactions */
});

HistoryPanelItem.propTypes = {
    language: LanguageType.isRequired,
    location: LocationType.isRequired,
    onClick: func.isRequired,
    query: string.isRequired,
    rank: number.isRequired,
    rankUpdatedAt: number.isRequired,
    type: number.isRequired,
    isPreselected: bool.isRequired,
};

export default HistoryPanelItem;
